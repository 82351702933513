.add {
  color: #fff;
  background-color: #783bff;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 1px;
  font-family: Nunito Sans, Arial;
  font-size: 1rem;
  font-weight: 400;
  line-height: 40px;
  height: 100%;
  width: 100%;
  display: flex;
}

.add:disabled {
  color: #fff;
  background-color: #9f99ff;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 1px;
  font-family: Nunito Sans, Arial;
  font-size: 1rem;
  font-weight: 400;
  line-height: 40px;
  display: flex;
}

.add:hover:not(:disabled) {
  color: #fff;
  background-color: #6a34df;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 1px;
  font-family: Nunito Sans, Arial;
  font-size: 1rem;
  font-weight: 400;
  line-height: 40px;
  display: flex;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
}

.delete {
  color: #fff;
  background-color: #f24683;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  font-family: Nunito Sans, Arial;
  font-size: 1rem;
  font-weight: 400;
  line-height: 40px;
}

.delete:hover {
  color: #fff;
  background-color: #d6316c;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: px;
  font-family: Nunito Sans, Arial;
  font-size: 1rem;
  font-weight: 400;
  line-height: 40px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
}

.record {
  color: #fff;
  background-color: #783bff;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  font-family: Nunito Sans, Arial;
  font-size: 1rem;
  font-weight: 400;
  line-height: 40px;
}

.record:hover {
  color: #fff;
  background-color: #6a34df;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  font-family: Nunito Sans, Arial;
  font-size: 1rem;
  font-weight: 400;
  line-height: 40px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
}

.view {
  color: #fff;
  background-color: #1425c0;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  font-family: Nunito Sans, Arial;
  font-size: 1rem;
  font-weight: 400;
  line-height: 40px;
}

.view:hover {
  color: #fff;
  background-color: #0414a1;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  font-family: Nunito Sans, Arial;
  font-size: 1rem;
  font-weight: 400;
  line-height: 40px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
}

.save {
  color: #fff;
  background-color: #783bff;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Nunito Sans, Arial;
  font-size: 1rem;
  font-weight: 400;
  line-height: 40px;
  width: 150px;
  display: flex;
}

.oncobutton {
    color: #fff;
    background-color: #783bff;
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: Nunito Sans, Arial;
    font-size: 1rem;
    font-weight: 400;
    line-height: 40px;
    width: 160px;
    display: flex;

}

.save:hover {
  color: #fff;
  background-color: #6a34df;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Nunito Sans, Arial;
  font-size: 1rem;
  font-weight: 400;
  line-height: 40px;
  width: 150px;
  display: flex;
}

.cancel {
  color: #fff;
  background-color: #bbbbbb;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Nunito Sans, Arial;
  font-size: 1rem;
  margin-right: 20px;
  width: 75px;
  display: flex;
  font-weight: 400;
  line-height: 40px;
}

.cancel:hover {
  color: #fff;
  background-color: #989899;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Nunito Sans, Arial;
  font-size: 1rem;
  display: flex;
  width: 75px;
  margin-right: 20px;
  font-weight: 400;
  line-height: 40px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
}

.footer {
  flex-direction: row;
  flex-basis: 23%;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
  margin-top: 5px;
}

.cancel,
.cancel:hover,
.delete,
.delete:hover {
  height: 100%;
  flex-basis: 20%;
}

.disabled {
  color: #fff;
  background-color: #9f99ff;
  flex-direction: row;
  justify-content: center;
  border-radius: 8px;
  align-items: center;
  padding-right: 1px;
  font-family: Nunito Sans, Arial;
  font-size: 1rem;
  font-weight: 400;
  line-height: 40px;
  display: flex;
}

/* .btn-close {
  color: rgba(1, 8, 90, 1);
} */

.ql-cutom-subjective {
  font-weight: bold;
  font-size: 24px;
  color: blue;
  background-color: lightgrey;
}

.ql-editor .ql-bold {
  font-weight: bold;
}

.ql-editor {
  font-size: 24px;
}

