body {
  height: 100vh;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 0;
  padding: 0;
}

.react-datepicker {
  font-size: 16px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 10px;
}

.react-datepicker__current-month {
  font-size: 2rem;
  margin-top: 2rem;
}

.react-datepicker__navigation {
  height: 8.5rem;
  width: 5.5rem;
}

.react-datepicker__navigation-icon::before {
  border-color: rgba(1, 8, 90, 1);
}

.react-datepicker__day-name {
  margin: 1.5rem;
  font-size: 1.5rem;
  color: rgba(1, 8, 90, 1);
}

.react-datepicker__current-month {
  color: rgba(1, 8, 90, 1);
}

.react-datepicker__day {
  font-size: 1.5rem;
  margin: 1.5rem;
  color: rgba(1, 8, 90, 1);
}

.react-datepicker__day--outside-month {
  color: transparent !important;
  pointer-events: none;
}

.react-datepicker__header {
  border-bottom: none;
}

.react-datepicker__day--selected {
  color: rgba(251, 249, 255, 1);
  background-color: #783bff;
}

.react-datepicker__today-button {
  background: #a278ff;
  color: white;
  padding: 1.5rem;
  border-radius: 0 0 0.5rem 0.5rem;
  font-size: 1.5rem;
}

.react-datepicker__day--today {
  font-weight: 400;
  border: none;
}

.react-datepicker__input-container {
  align-items: center;
  display: flex;
  cursor: pointer;
  width: 100%;
  padding: 0.5rem;
}
.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 35px;
  width: 26rem;
}

.react-datepicker__input-container {
  border: none;
  border-radius: 10px;
}

.react-datepicker__calendar-icon {
  color: rgba(1, 8, 90, 1);
}
/*
body {
  justify-content: space-around;
} */
