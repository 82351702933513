.buttons {
    color: #fff;
    background-color: #783BFF;
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 1px;
    font-family: Nunito Sans, Arial;
    font-size: 1rem;
    font-weight: 400;
    line-height: 40px;
    height: 100%;
    width: 100%;
    display: flex;
}

.buttons:hover {
    color: #fff;
    background-color: #6a34df;
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 1px;
    font-family: Nunito Sans, Arial;
    font-size: 1rem;
    font-weight: 400;
    line-height: 40px;
    display: flex;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2)
}

.background{
    width: 100%;
    height: 120%;
    object-fit: cover;
}

.logo {
    width: 400px;
    margin-top: 80px;
}
