@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loader {
    border: 14px solid rgba(0, 0, 0, 0.1); 
    border-radius: 50%;
    border-top: 14px solid #783BFF; 
    width: 175px;
    height: 175px;
    animation: spin 1.5s linear infinite;
  }