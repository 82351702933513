.return {
    color: #fff;
    background-color: #783BFF;
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 1px;
    font-family: Nunito Sans, Arial;
    font-size: 1rem;
    font-weight: 400;
    line-height: 40px;
    height: 100%;
    width: 100px;
    display: flex;
}

.return:hover {
    color: #fff;
    background-color: #6a34df;
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 1px;
    font-family: Nunito Sans, Arial;
    font-size: 1rem;
    font-weight: 400;
    line-height: 40px;
    display: flex;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2)
}

.record {
    color: #fff;
    background-color: #f24683;
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: Nunito Sans, Arial;
    font-size: 1rem;
    width: 400px;
    font-weight: 400;
    line-height: 40px;
}

.record:hover {
    color: #fff;
    background-color: #D6316C;
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: px;
    font-family: Nunito Sans, Arial;
    font-size: rem;
    font-weight: 400;
    width: 400px;
    line-height: 40px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2)
}

.continue {
    color: #fff;
    background-color: #783BFF;
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    font-family: Nunito Sans, Arial;
    font-size: 1rem;
    font-weight: 400;
    width: 400px;
    line-height: 40px;
}

.continue:hover {
    color: #fff;
    background-color: #6a34df;
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: px;
    font-family: Nunito Sans, Arial;
    font-size: rem;
    font-weight: 400;
    width: 400px;
    line-height: 40px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2)
}

.stop {
    color: #fff;
    background-color: #f24683;
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    font-family: Nunito Sans, Arial;
    font-size: 1rem;
    font-weight: 400;
    line-height: 40px;
    width: 400px;
}

.stop:hover {
    color: #fff;
    background-color: #D6316C;
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: px;
    font-family: Nunito Sans, Arial;
    font-size: rem;
    font-weight: 400;
    line-height: 40px;
    width: 400px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2)
}

.pause {
    color: #333333;
    background-color: #9CF629;
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    font-family: Nunito Sans, Arial;
    font-size: 1rem;
    font-weight: 400;
    line-height: 40px;
    width: 400px;

}

.pause:hover {
    color: #fff;
    background-color: #93DC36;
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: px;
    font-family: Nunito Sans, Arial;
    font-size: rem;
    font-weight: 400;
    line-height: 40px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
    width: 400px;
}

.upload {
    color: #fff;
    background-color: #783BFF;
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    font-family: Nunito Sans, Arial;
    font-size: 1rem;
    font-weight: 400;
    line-height: 40px;
    width: 400px;
}

.upload:hover {
    color: #fff;
    background-color: #6a34df;
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    font-family: Nunito Sans, Arial;
    font-size: 1rem;
    font-weight: 400;
    line-height: 40px;
    width: 400px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2)
}

.clear {
    color: #fff;
    background-color: #f24683;
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    font-family: Nunito Sans, Arial;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 40px;
    width: 400px;
}

/* .clear:hover {
    color: #fff;
    background-color: #D6316C;
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: px;
    font-family: Nunito Sans, Arial;
    font-size: rem;
    font-weight: 400;
    line-height: 40px;
    width: 400px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2)
} */

.delete {
    color: #fff;
    background-color: #f24683;
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    font-family: Nunito Sans, Arial;
    font-size: 1rem;
    font-weight: 400;
    line-height: 40px;
    margin-right: 20px;
}

.delete:hover {
    color: #fff;
    background-color: #D6316C;
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: px;
    font-family: Nunito Sans, Arial;
    font-size: 1rem;
    font-weight: 400;
    line-height: 40px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2)
}

.footer {
    flex-direction: row;
    flex-basis: 23%;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    display: flex;
    margin-top: 5px;
}
.cancel {
    color: #fff;
    background-color: #BBBBBB;
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: Nunito Sans, Arial;
    font-size: 1rem;
    margin-right: 20px;
    width: 75px;
    display: flex;
    font-weight: 400;
    line-height: 40px;
}

.cancel:hover {
    color: #fff;
    background-color: #989899;
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: Nunito Sans, Arial;
    font-size: 1rem;
    display: flex;
    width: 75px;
    margin-right: 20px;
    font-weight: 400;
    line-height: 40px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2)
}

.cancel,
.cancel:hover,
.delete,
.delete:hover,
.return,
.return:hover {
    height: 100%;
    flex-basis: 20%;
}

.audioControls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.controlButton,
.audioControlsButton {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background: linear-gradient(90deg, #FF5F6D, #FFC371);
    color: #fff;
    cursor: pointer;
    transition: 0.3s all;
    margin: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.audioPlayerAudio {
    width: 100%;
}

.audio-player,
.video-player,
.recorded-player {
    display: flex;
    align-items: center;
    
}

.live-player {
    height: 200px;
    width: 400px;
    border: 1px solid #646cff;
    margin-bottom: 30px;
}

.recorded-player video {
    height: 400px;
    width: 800px;
}

body {
    margin: 0;
    display: flex;
    place-items: flex-start;
    min-width: 320px;
    min-height: 100vh;
    height: fit-content;
    width: fit-content;
    min-height: 100vh;

}


.timer {
    font-size: 2em;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.loadingAnimation {
    padding-left: 10;
}

